
.app__balls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem 4rem;

  .ball-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: auto;  
    height: auto; 

    .ball-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ball-name {
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: 600;
      }

      .ball-description {
        line-height: 1.5;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: all 1s ease-in-out;
      }
    }

    &.active {
      .ball-description {
        opacity: 1;
        height: auto;
        transition: all 1s ease-in-out;
      }
    }
  }
}

.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}