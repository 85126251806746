.app__experience {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  

.vertical-timeline.vertical-timeline-custom-line::before {
    background: rgb(209, 204, 204);
  }
  
  /* Icon container's border */
  .vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon,
  .vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--education .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px blue, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05); 
  }

  
  .app__experience-timeline {
    display: flex;
    flex-direction: column;
    margin: 4rem 0 2rem;
  }
  
  .experience__card {
    padding: 1rem;
    background-color: #edf2f8;
    color: #fff;
    border-radius: 0.5rem;
    margin: 0.5rem;
  
    transition: all 0.3s ease;
  
    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }
  
    @media screen and (min-width: 2000px) {
      padding: 1.25rem;
      border-radius: 0.75rem;
    }
  }
  
  .experience__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem;
  }
  
  .experience__logo-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .experience__title {
    margin-top: 1rem;
    font-size: 24px;
    font-weight: bold;
  }
  
  .experience__company {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: var(--secondary-color);
  }
  
  .experience__points {
    margin-top: 1rem;
    list-style-type: disc;
    margin-left: 1.25rem;
  
    @media screen and (max-width: 900px) {
      margin-left: 1rem;
    }
  }
  
  .experience__point-item {
    font-size: 14px;
    letter-spacing: 0.05rem;
    color: #000000;
  }
  