@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.cursor { 
	pointer-events: none;
	position: fixed; 
    top: 10px; 
    left: 10px;
	padding: 10px;
	background-color: #fff;
	border-radius: 50%; 
	transform: translate(-50%, -50%);
	mix-blend-mode: difference;
	transition: transform .8s ease, opacity 0.6s ease;
	z-index: 2;
}